var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"app"}},[(_vm.gameIsOver)?_c('div',{staticClass:"overlay__game-over"}):_vm._e(),_c('div',{staticClass:"game-area"},[_c('div',{class:{
                'player-health-container': true,
                'player-health-container--won': _vm.gameWon,
                'player-health-container--lost': _vm.gameLost,
            }},[_c('span',{class:{
                    'player-health': true,
                    'player-health--positive':
                        _vm.playerHealthHistory.length > 0 &&
                        _vm.playerHealthHistory[0].modifier > 0 &&
                        _vm.playerHealthHistory[0].turn + _vm.scoreHistoryEffectTurns >= _vm.turns &&
                        !_vm.gameWon,
                    'player-health--negative':
                        _vm.playerHealthHistory.length > 0 &&
                        _vm.playerHealthHistory[0].modifier < 0 &&
                        _vm.playerHealthHistory[0].turn + _vm.scoreHistoryEffectTurns >= _vm.turns &&
                        !_vm.gameWon,
                }},[_vm._v(" "+_vm._s(_vm.playerHealth)+" ")]),_c('span',{class:{
                    'player-health-total': true,
                }},[_vm._v(" "+_vm._s(' / ' + _vm.healthGoal)+" ")])]),_vm._l((_vm.world),function(rows,y){return _c('div',{key:y,staticClass:"row"},_vm._l((rows),function(cell,x){return _c('div',{key:x,staticClass:"cell",class:_vm.cellClasses(cell, x, y)},[_vm._v(" "+_vm._s((_vm.isPlayerCell(x, y) ? _vm.playerEmoji : '') + (_vm.isPlayerCell(x, y) ? _vm.heldItems.map(function (item) { return item.symbol(); }).join('') : ''))+" ")])}),0)}),(
                _vm.announcementMessage !== '' &&
                _vm.announcementTurn > _vm.turns - _vm.turnsToDisplayAnnouncements
            )?_c('div',{class:{
                'announcement-container': true,
            }},[_c('p',[_vm._v(_vm._s(_vm.announcementMessage))])]):_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }